<template>
  <div class="offers">
    <div class="offers__table">
      <el-table
        :data="projectOffers"
        :row-class-name="tableRowClassName"
        @header-click="sortData"
      >
        <el-table-column
          :index="0"
          :label-class-name="
            typeSort === 'title' ? 'sortable ' + sortOrder : ''
          "
        >
          <template slot="header">
            <span class="hide-sm  hide-xs">Полное имя</span>
            <span class="hide-lg  hide-md">Специальность</span>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.worker" class="offers__table-item">
              <span
                class="offers__table-item--bold"
                @click="getDetailEmployee(scope.row.worker_id)"
              >
                {{ scope.row.worker ? scope.row.worker.name : "" }}
              </span>
              <br />
              <a :href="'tel:' + scope.row.worker.phone">
                {{ scope.row.worker ? scope.row.worker.phone : "" }}
              </a>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header">
            <span class="hide-sm  hide-xs">E-mail</span>
            <span class="hide-lg  hide-md">Оплата</span>
          </template>
          <template slot-scope="scope">
            {{ scope.row.worker ? scope.row.worker.email : "" }}
          </template>
        </el-table-column>
        <el-table-column :index="2" label="Специальности">
          <template slot="header" slot-scope="scope">
            <span class="table-th--title">Специальности</span>
            <el-select
              v-if="specialitiesList && specialitiesList.length"
              :key="scope.column.index"
              class="el-select_filter"
              v-model="selectedSpecialities"
              @change="getOffers"
              placeholder="Все специальности"
            >
              <el-option
                v-for="item in specialitiesAllList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            {{ scope.row.speciality.name }}
          </template>
        </el-table-column>
        <el-table-column :index="3">
          <template slot="header" slot-scope="scope">
            <span class="table-th--title">Оплата</span>
            <el-select
              :key="scope.column.index"
              v-model="selectedTypePayment"
              class="el-select_filter"
              @change="getOffers"
              placeholder="Все типы"
            >
              <el-option :label="'Все типы'" :value="'all'">
                Все типы
              </el-option>
              <el-option :label="'Почасовая'" :value="'pay_by_hours'">
                Почасовая
              </el-option>
              <el-option :label="'Сдельная'" :value="'piece_work_payment'">
                Сдельная
              </el-option>
            </el-select>
          </template>
          <template slot-scope="scope">
            {{ scope.row.payment }}
            {{ scope.row.pay_by_hours ? "баллов/час" : "баллов/месяц" }}
          </template>
        </el-table-column>
        <el-table-column :index="4" label="Показывать ставку">
          <template slot-scope="scope">
            {{ scope.row.show_stavka ? "Да" : "Нет" }}
          </template>
        </el-table-column>
        <el-table-column :index="5" prop="workday_start" label="График">
          <template slot-scope="scope">
            <div
              @click="showShedule"
              class="el-dropdown-link  hide-lg  hide-md"
            >
              <img src="@/assets/icons/icon-timer.svg" />
              <div class="hide-sm  hide-xs  js-content">
                <div class="schedule">
                  <div class="schedule-row">
                    <div class="schedule-title schedule-title--days">
                      Дни:
                    </div>
                    <div class="schedule-value">
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.mon
                        }"
                        >ПН
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.tue
                        }"
                        >ВТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.wed
                        }"
                        >СР
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.thu
                        }"
                        >ЧТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.fri
                        }"
                        >ПТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.sat
                        }"
                        >СБ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.sun
                        }"
                        >ВС
                      </span>
                    </div>
                  </div>
                  <div v-if="scope.row.workday_start" class="schedule-row">
                    <div class="schedule-title schedule-title--time">
                      Рабочее время:
                    </div>
                    <div class="schedule-value">
                      {{ scope.row.workday_start.substr(0, 5) }}
                      -
                      {{ scope.row.workday_end.substr(0, 5) }}
                    </div>
                  </div>
                  <div v-if="scope.row.dinner_start" class="schedule-row">
                    <div class="schedule-title schedule-title--time">
                      Обед:
                    </div>
                    <div class="schedule-value">
                      {{ scope.row.dinner_start.substr(0, 5) }}
                      -
                      {{ scope.row.dinner_end.substr(0, 5) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-dropdown
              trigger="click"
              @command="handleCommand"
              class="hide-sm  hide-xs"
            >
              <span class="el-dropdown-link">
                <img src="@/assets/icons/icon-timer.svg" />
              </span>
              <el-dropdown-menu slot="dropdown" class="offers__options">
                <div class="schedule">
                  <div class="schedule-row">
                    <div class="schedule-title schedule-title--days">
                      Дни:
                    </div>
                    <div class="schedule-value">
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.mon
                        }"
                        >ПН
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.tue
                        }"
                        >ВТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.wed
                        }"
                        >СР
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.thu
                        }"
                        >ЧТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.fri
                        }"
                        >ПТ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.sat
                        }"
                        >СБ
                      </span>
                      <span
                        :class="{
                          'schedule-value--active': scope.row.workweek.sun
                        }"
                        >ВС
                      </span>
                    </div>
                  </div>
                  <div v-if="scope.row.workday_start" class="schedule-row">
                    <div class="schedule-title schedule-title--time">
                      Рабочее время:
                    </div>
                    <div class="schedule-value">
                      {{ scope.row.workday_start.substr(0, 5) }}
                      -
                      {{ scope.row.workday_end.substr(0, 5) }}
                    </div>
                  </div>
                  <div v-if="scope.row.dinner_start" class="schedule-row">
                    <div class="schedule-title schedule-title--time">
                      Обед:
                    </div>
                    <div class="schedule-value">
                      {{ scope.row.dinner_start.substr(0, 5) }}
                      -
                      {{ scope.row.dinner_end.substr(0, 5) }}
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column :index="6" label="Статус">
          <template slot-scope="scope">
            <div class="offers__table-status__wrapper">
              <span>
                {{
                  scope.row.day_status === "work"
                    ? "Работает"
                    : scope.row.day_status === "pause"
                    ? "Перерыв"
                    : scope.row.day_status === "didnt start"
                    ? "Не работает"
                    : scope.row.day_status === "end"
                    ? "Отработал"
                    : ""
                }}
              </span>
              <div
                class="offers__table-status"
                :class="{
                  active: scope.row.day_status === 'work',
                  pause: scope.row.day_status === 'pause',
                  didnt_work: scope.row.day_status === 'didnt start',
                  finish: scope.row.day_status === 'end'
                }"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="7" width="100">
          <!-- <template slot="header">
            <img class="offers__table-actions__chat" src="@/assets/icons/icon-chat.svg"
                 />
                 @click="openChat(scope.row.id, scope.row.title)"
          </template> -->
          <template slot-scope="scope">
            <div class="offers__table-actions">
              <img
                v-if="scope.row.worker_id"
                class="offers__table-actions__chat"
                src="@/assets/icons/icon-chat.svg"
                @click="openChatUser(scope)"
              />
              <!-- <img v-if="scope.row.worker_id" class="offers__table-actions__chat" src="@/assets/icons/icon-chat.svg"
                @click="openChat(scope)" /> -->
              <div class="more-options  hide-lg  hide-md">
                <span
                  @click="scope.row.visibleMoreOptions = true"
                  class="el-dropdown-link"
                >
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
                <el-dialog
                  title="Действия"
                  :visible.sync="scope.row.visibleMoreOptions"
                  width="288px"
                >
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'edit', id: scope.row });
                    "
                  >
                    <img src="@/assets/icons/icon-edit.svg" />
                    Редактировать
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="!scope.row.worker"
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'hire', id: scope.row.id });
                    "
                  >
                    <img src="@/assets/icons/icon-tools.svg" />
                    Нанять работника
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-else
                    class="removed"
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({
                        name: 'dismiss',
                        id: scope.row.id,
                        worker: scope.row.worker_id
                      });
                    "
                  >
                    <img src="@/assets/icons/icon-removed-red.svg" />
                    Уволить из проекта
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="!scope.row.worker"
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'delete', id: scope.row.id });
                    "
                    class="removed"
                  >
                    <img src="@/assets/icons/icon-removed-red.svg" />
                    Удалить вакансию
                  </el-dropdown-item>
                </el-dialog>
              </div>
              <el-dropdown
                trigger="click"
                @command="handleCommand"
                class="hide-sm  hide-xs"
              >
                <span class="el-dropdown-link">
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
                <el-dropdown-menu slot="dropdown" class="offers__options">
                  <el-dropdown-item :command="{ name: 'edit', id: scope.row }">
                    <img src="@/assets/icons/icon-edit.svg" />
                    Редактировать
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="!scope.row.worker"
                    :command="{ name: 'hire', id: scope.row.id }"
                  >
                    <img src="@/assets/icons/icon-tools.svg" />
                    Нанять работника
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-else
                    class="removed"
                    :command="{
                      name: 'dismiss',
                      id: scope.row.id,
                      worker: scope.row.worker_id
                    }"
                  >
                    <img src="@/assets/icons/icon-removed-red.svg" />
                    Уволить из проекта
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="!scope.row.worker"
                    :command="{ name: 'delete', id: scope.row.id }"
                    class="removed"
                  >
                    <img src="@/assets/icons/icon-removed-red.svg" />
                    Удалить вакансию
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="offers__nav">
      <Pagination :info="offersPagination" @changedPage="changePage" />
    </div>
  </div>
</template>

<script>
import Pagination from "./Pagination";

export default {
  name: "OffersTable",
  components: {
    Pagination
  },
  data() {
    return {
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 8,
      selectedSpecialities: "all",
      selectedTypePayment: "all"
    };
  },
  computed: {
    projectOffers() {
      return this.$store.state.Projects.projectOffers?.map(item => ({
        ...item,
        visibleMoreOptions: false
      }));
    },
    offersPagination() {
      return this.$store.state.Projects.offersPagination;
    },
    specialitiesList() {
      return this.$store.state.Projects.specialitiesList;
    },
    specialitiesAllList() {
      let arr = [];
      if (this.specialitiesList) {
        arr = JSON.parse(JSON.stringify(this.specialitiesList));
        arr.unshift({ id: "all", name: "Все специальности" });
      }
      return arr;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    successCreate() {
      return this.$store.state.Projects.successCreate;
    },
    currentCompany() {
      return this.$store.state.Projects.projectData.company_id;
    }
  },
  watch: {
    successCreate(val) {
      if (val) {
        this.getOffers();
        this.$store.commit("Projects/setData", {
          label: "successCreate",
          data: false
        });
      }
    }
  },
  methods: {
    getDetailEmployee(id) {
      this.$router.push({
        name: "Employee",
        params: { id: id }
      });
    },
    tableRowClassName({ row }) {
      if (!row.worker_id) {
        return "red-row";
      }
      return "";
    },
    getOffers() {
      this.$store.dispatch("Projects/getOffers", {
        id: this.$route.params.id,
        page: this.page,
        pageCount: this.pageCount,
        sortField: this.typeSort ? this.typeSort : null,
        sortBy: this.typeSort ? this.sortOrder : null,
        speciality:
          this.selectedSpecialities === "all"
            ? null
            : this.selectedSpecialities,
        payment_type:
          this.selectedTypePayment === "all" ? null : this.selectedTypePayment
      });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.getOffers();
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          this.typeSort === "title"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "title";
          break;
      }
      this.getOffers();
    },
    handleCommand(command) {
      switch (command.name) {
        case "delete":
          this.removeVacancy(command.id);
          break;
        case "edit":
          this.$store.commit("Option/setData", {
            label: "detailVacancy",
            data: command.id
          });
          this.$store.commit("Option/setData", {
            label: "currentOption",
            data: "AddOffer"
          });
          break;
        case "hire":
          this.$router.push({
            path: `/hire?company=${this.currentCompany}&vacancy=${command.id}`
          });
          break;
        case "dismiss":
          this.dismissEmployee(command.id, command.worker);
          break;
      }
    },
    removeVacancy(id) {
      this.$store
        .dispatch("Projects/removeOffer", id)
        .then(() => {
          this.getOffers();
          this.$notify({
            title: "Успех",
            message: "Вакансия удалена",
            type: "success"
          });
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    dismissEmployee(id, worker) {
      const data = {
        worker_id: worker
      };
      this.$store
        .dispatch("Projects/dismissEmployee", { data, id })
        .then(() => {
          this.getOffers();
          this.$notify({
            title: "Успех",
            message: "Работник уволен",
            type: "success"
          });
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    openChat(data) {
      this.$store.commit("Chat/setData", {
        label: "currentChat",
        data: { type: "project", id: data.row.id, name: data.row.title }
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "Chat"
      });
    },
    openChatUser(data) {
      let { worker, worker_id } = data.row;
      this.$store.commit("Chat/setData", {
        label: "currentChat",
        data: {
          type: "user",
          id: worker_id,
          name: worker.name,
          image: process.env.VUE_APP_BASE_URL + worker.image
        }
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "Chat"
      });
    },
    showShedule(e) {
      this.$confirm(
        `<div class="remove-message">
				<img src="${require("@/assets/icons/icon-info-blue.svg")}" alt="Описание вакансии:">
				<div>
				  <div class="remove-message__title">
					График:
				  </div>
				  <div class="remove-message__text">
					${e.currentTarget.querySelector(".js-content").innerHTML}
				  </div>
				</div>
			  </div>`,
        {
          customClass: "remove-message__box  remove-message__box--warning",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Понятно",
          showClose: false
        }
      );
    }
  },
  created() {
    this.getOffers();
    this.$store.dispatch("Projects/getSpecialities");
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.offers {
  &__table {
    &-status__wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      span {
        // white-space: nowrap;
      }
    }

    &-status {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin-left: 10px;

      &.active {
        background: $active;
      }

      &.pause {
        background: $pause;
      }

      &.didnt_work {
        background: $error;
      }

      &.finish {
        background: $error;
      }
    }

    &-employees {
      display: flex;
      font-size: 1rem;
      line-height: 1.4rem;

      & > div {
        width: 3.6rem;
        height: 2.6rem;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__hired {
        background: rgba(104, 218, 136, 0.15);
        border: 1px solid $active;
        color: $active;
      }

      &__needed {
        background: rgba(221, 74, 100, 0.15);
        border: 1px solid $error;
        color: $error;
        margin-left: 0.8rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
        cursor: pointer;
      }
    }

    .el-table th {
      height: 8.7rem;
      padding: 0;
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $primary;
        cursor: pointer;

        &:hover {
          color: $text;
        }
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }

  .el-table__body-wrapper {
    td {
      &:nth-child(1) {
        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

.schedule {
  padding: 1rem 2.2rem;

  &-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
    font-size: 1.2rem;
    line-height: 1.6rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-title {
    font-weight: 700;
    color: #2f3138;

    &--days {
      min-width: 4.2rem;
    }

    &--time {
      min-width: 10.2rem;
    }
  }

  &-value {
    color: $form-def-placeholder-color;

    &--active {
      color: $text;
    }

    span {
      margin: 0 0.5rem;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .offers {
    .offers__table {
      margin: 0 -16px;
      &-status__wrapper {
        span {
          display: none;
        }
      }
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        height: auto;

        &:nth-child(n) {
          border-radius: 0;
          width: calc(50% - 12px);
          margin-top: 16px;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &:nth-child(2) {
          width: calc(50% + 12px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin: 0 16px 4px;
          order: -15;
          width: 100%;
          background: none;

          .cell {
            padding: 0;
          }

          .table-th--title {
            display: none;
          }
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
        min-height: 85px;

        &.red-row {
          td {
            color: #dd4a64;
          }
        }
      }

      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;

        &:nth-child(3) {
          width: calc(50% - 12px);
          order: -25;

          .offers__table-item--bold {
            color: #31333b;
          }
        }

        &:nth-child(4) {
          width: calc(50% - 12px);
          order: -20;
          color: #1199f0;
        }

        &:nth-child(7) {
          width: 24px;
          order: -15;
        }

        &:nth-child(1) {
          width: 100%;
          order: -10;
          font-size: 12px;
          line-height: 16px;

          a {
            color: #1199f0;
          }
        }

        &:nth-child(2),
        &:nth-child(5) {
          display: none;
        }

        &:nth-child(6) {
          right: 16px;
          bottom: 10px;
          margin: 0;
          padding: 0;
          margin-left: auto;
          margin-right: 16px;

          .el-dropdown-selfdefine {
            display: flex;
          }
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }

      .offers__table-actions__chat {
        right: 60px;
        bottom: 16px;
        margin: 0;
        margin-right: 16px;

        img {
          margin: 0;
          position: static;
        }
      }
    }
  }

  .professions__table-email {
    font-size: 12px;
    line-height: 16px;
    color: #1199f0;
  }

  .offers__table-actions {
    padding: 0;
  }

  .remove-message__text {
    .schedule {
      padding: 0;
      margin-top: 10px;
    }
  }
}
</style>
