<template>
  <div v-if="projectData" class="project">
    <div class="project__title">
      <a @click.prevent="goBack" class="project__title-link">
        <img src="@/assets/icons/icon-arrow.svg" alt="" />
      </a>
      <PageTitle :text="projectData.title ? projectData.title : 'Новый проект'" />
      <el-button
        type="primary"
        class="project__title-button"
        @click="addOffer"
      >
		  <span class="hide-xs">+ </span>Добавить
		  <span class="hide-xs">вакансию</span>
      </el-button>
    </div>
    <div class="project__tabs" :class="['project__tabs--' + activeTab]">
      <el-tabs @tab-click="changeTab" v-model="activeTab">
        <el-tab-pane label="Пользователи" name="vacancy">
          <OffersTable />
        </el-tab-pane>
        <el-tab-pane label="Информация" name="info">
          <ProjectCard v-if="isLoad" :data-info="projectData" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import ProjectCard from '../components/blocks/ProjectCard'
import OffersTable from '../components/blocks/OffersTable'

export default {
  name: 'Project',
  components: {
    PageTitle,
    ProjectCard,
    OffersTable,
  },
  data() {
    return {
      isOffer: true,
      activeTab: 'vacancy',
      fromRoute: null,
      isLoad: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRoute = from
    })
  },
  watch: {
    $route(to, from) {
      if (from.query.type === 'vacancy') {
        this.activeTab = 'info'
      } else {
        this.activeTab = 'vacancy'
      }
    },
  },
  computed: {
    projectData() {
      return this.$store.state.Projects.projectData
    },
  },
  async created() {
    this.isLoad = false;
    if (this.$route.params) {
      const id = this.$route.params
      await this.getProject(id);
      this.isLoad = true;
    }
    if (this.$route.query.type) {
      if (this.$route.query.type === 'vacancy') {
        this.activeTab = 'vacancy'
        this.isOffer = true
      } else {
        this.activeTab = 'info'
        this.isOffer = false
      }
    }
    if (this.$route.query.for) {
      this.addOffer()
    }
  },
  methods: {
    getProject(id) {
      this.$store.dispatch('Projects/getProject', id)
    },
    changeTab(tab) {
      // При переключении табов необходимо обнулять переменную "fromRoute",
      // чтобы роут перекидывал на 'Projects', а не отрабатывал $router.back()
      this.fromRoute = null
      console.log('tab.label', tab.label)
      if (tab.label === 'Вакансии') {
        this.isOffer = true
        const query = {
          type: 'vacancy',
        }
        this.$router.push({ query })
      } else {
        this.isOffer = false
        const query = {}
        this.$router.push({ query })
      }
    },
    addOffer() {
      this.$store.commit('Option/setData', {
        label: 'currentOption',
        data: 'AddOffer',
      })
    },
    goBack() {
      if (!this.fromRoute || !this.fromRoute.name) {
        this.$router.push({ name: 'Projects' })
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.project {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
    position: relative;
    z-index: 2;

    &-button {
      position: absolute;
      right: 0;
      bottom: -5rem;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }
  }
}

@media (max-width: $screen-sm-max){
	.project__tabs--vacancy{
		margin: 0 -16px;

		.el-tab-pane{
			background: none;
			padding: 0 16px;
		}
	}
}

@media (max-width: $screen-xs-max){
	.project__title-link{
		display: none;
	}
	.project__tabs{
		margin: 0 -16px;
	}
	.project__title-button{
		margin-left: auto;
		position: static;
		padding: 0;
		background: none;
		color: #1199F0;
		border-bottom: 1px solid #1199F0;
		border-radius: 0;
	}
}
</style>
