var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "offers"
  }, [_c('div', {
    staticClass: "offers__table"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.projectOffers,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'sortable ' + _vm.sortOrder : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.worker ? _c('div', {
          staticClass: "offers__table-item"
        }, [_c('span', {
          staticClass: "offers__table-item--bold",
          on: {
            "click": function click($event) {
              return _vm.getDetailEmployee(scope.row.worker_id);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.worker ? scope.row.worker.name : "") + " ")]), _c('br'), _c('a', {
          attrs: {
            "href": 'tel:' + scope.row.worker.phone
          }
        }, [_vm._v(" " + _vm._s(scope.row.worker ? scope.row.worker.phone : "") + " ")])]) : _vm._e()];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm  hide-xs"
  }, [_vm._v("Полное имя")]), _c('span', {
    staticClass: "hide-lg  hide-md"
  }, [_vm._v("Специальность")]), _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })])])], 2), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.worker ? scope.row.worker.email : "") + " ")];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm  hide-xs"
  }, [_vm._v("E-mail")]), _c('span', {
    staticClass: "hide-lg  hide-md"
  }, [_vm._v("Оплата")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 2,
      "label": "Специальности"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "table-th--title"
        }, [_vm._v("Специальности")]), _vm.specialitiesList && _vm.specialitiesList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "placeholder": "Все специальности"
          },
          on: {
            "change": _vm.getOffers
          },
          model: {
            value: _vm.selectedSpecialities,
            callback: function callback($$v) {
              _vm.selectedSpecialities = $$v;
            },
            expression: "selectedSpecialities"
          }
        }, _vm._l(_vm.specialitiesAllList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.name,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.speciality.name) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 3
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "table-th--title"
        }, [_vm._v("Оплата")]), _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "placeholder": "Все типы"
          },
          on: {
            "change": _vm.getOffers
          },
          model: {
            value: _vm.selectedTypePayment,
            callback: function callback($$v) {
              _vm.selectedTypePayment = $$v;
            },
            expression: "selectedTypePayment"
          }
        }, [_c('el-option', {
          attrs: {
            "label": 'Все типы',
            "value": 'all'
          }
        }, [_vm._v(" Все типы ")]), _c('el-option', {
          attrs: {
            "label": 'Почасовая',
            "value": 'pay_by_hours'
          }
        }, [_vm._v(" Почасовая ")]), _c('el-option', {
          attrs: {
            "label": 'Сдельная',
            "value": 'piece_work_payment'
          }
        }, [_vm._v(" Сдельная ")])], 1)];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.payment) + " " + _vm._s(scope.row.pay_by_hours ? "баллов/час" : "баллов/месяц") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 4,
      "label": "Показывать ставку"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.show_stavka ? "Да" : "Нет") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 5,
      "prop": "workday_start",
      "label": "График"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "el-dropdown-link  hide-lg  hide-md",
          on: {
            "click": _vm.showShedule
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-timer.svg")
          }
        }), _c('div', {
          staticClass: "hide-sm  hide-xs  js-content"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--days"
        }, [_vm._v(" Дни: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.mon
          }
        }, [_vm._v("ПН ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.tue
          }
        }, [_vm._v("ВТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.wed
          }
        }, [_vm._v("СР ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.thu
          }
        }, [_vm._v("ЧТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.fri
          }
        }, [_vm._v("ПТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sat
          }
        }, [_vm._v("СБ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sun
          }
        }, [_vm._v("ВС ")])])]), scope.row.workday_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Рабочее время: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_vm._v(" " + _vm._s(scope.row.workday_start.substr(0, 5)) + " - " + _vm._s(scope.row.workday_end.substr(0, 5)) + " ")])]) : _vm._e(), scope.row.dinner_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Обед: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_vm._v(" " + _vm._s(scope.row.dinner_start.substr(0, 5)) + " - " + _vm._s(scope.row.dinner_end.substr(0, 5)) + " ")])]) : _vm._e()])])]), _c('el-dropdown', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-timer.svg")
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "offers__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--days"
        }, [_vm._v(" Дни: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.mon
          }
        }, [_vm._v("ПН ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.tue
          }
        }, [_vm._v("ВТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.wed
          }
        }, [_vm._v("СР ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.thu
          }
        }, [_vm._v("ЧТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.fri
          }
        }, [_vm._v("ПТ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sat
          }
        }, [_vm._v("СБ ")]), _c('span', {
          class: {
            'schedule-value--active': scope.row.workweek.sun
          }
        }, [_vm._v("ВС ")])])]), scope.row.workday_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Рабочее время: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_vm._v(" " + _vm._s(scope.row.workday_start.substr(0, 5)) + " - " + _vm._s(scope.row.workday_end.substr(0, 5)) + " ")])]) : _vm._e(), scope.row.dinner_start ? _c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title schedule-title--time"
        }, [_vm._v(" Обед: ")]), _c('div', {
          staticClass: "schedule-value"
        }, [_vm._v(" " + _vm._s(scope.row.dinner_start.substr(0, 5)) + " - " + _vm._s(scope.row.dinner_end.substr(0, 5)) + " ")])]) : _vm._e()])])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 6,
      "label": "Статус"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "offers__table-status__wrapper"
        }, [_c('span', [_vm._v(" " + _vm._s(scope.row.day_status === "work" ? "Работает" : scope.row.day_status === "pause" ? "Перерыв" : scope.row.day_status === "didnt start" ? "Не работает" : scope.row.day_status === "end" ? "Отработал" : "") + " ")]), _c('div', {
          staticClass: "offers__table-status",
          class: {
            active: scope.row.day_status === 'work',
            pause: scope.row.day_status === 'pause',
            didnt_work: scope.row.day_status === 'didnt start',
            finish: scope.row.day_status === 'end'
          }
        })])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 7,
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "offers__table-actions"
        }, [scope.row.worker_id ? _c('img', {
          staticClass: "offers__table-actions__chat",
          attrs: {
            "src": require("@/assets/icons/icon-chat.svg")
          },
          on: {
            "click": function click($event) {
              return _vm.openChatUser(scope);
            }
          }
        }) : _vm._e(), _c('div', {
          staticClass: "more-options  hide-lg  hide-md"
        }, [_c('span', {
          staticClass: "el-dropdown-link",
          on: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = true;
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dialog', {
          attrs: {
            "title": "Действия",
            "visible": scope.row.visibleMoreOptions,
            "width": "288px"
          },
          on: {
            "update:visible": function updateVisible($event) {
              return _vm.$set(scope.row, "visibleMoreOptions", $event);
            }
          }
        }, [_c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'edit',
                id: scope.row
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-edit.svg")
          }
        }), _vm._v(" Редактировать ")]), !scope.row.worker ? _c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'hire',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-tools.svg")
          }
        }), _vm._v(" Нанять работника ")]) : _c('el-dropdown-item', {
          staticClass: "removed",
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'dismiss',
                id: scope.row.id,
                worker: scope.row.worker_id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Уволить из проекта ")]), !scope.row.worker ? _c('el-dropdown-item', {
          staticClass: "removed",
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'delete',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Удалить вакансию ")]) : _vm._e()], 1)], 1), _c('el-dropdown', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "offers__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'edit',
              id: scope.row
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-edit.svg")
          }
        }), _vm._v(" Редактировать ")]), !scope.row.worker ? _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'hire',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-tools.svg")
          }
        }), _vm._v(" Нанять работника ")]) : _c('el-dropdown-item', {
          staticClass: "removed",
          attrs: {
            "command": {
              name: 'dismiss',
              id: scope.row.id,
              worker: scope.row.worker_id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Уволить из проекта ")]), !scope.row.worker ? _c('el-dropdown-item', {
          staticClass: "removed",
          attrs: {
            "command": {
              name: 'delete',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Удалить вакансию ")]) : _vm._e()], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "offers__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.offersPagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }