var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.projectData ? _c('div', {
    staticClass: "project"
  }, [_c('div', {
    staticClass: "project__title"
  }, [_c('a', {
    staticClass: "project__title-link",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goBack($event);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-arrow.svg"),
      "alt": ""
    }
  })]), _c('PageTitle', {
    attrs: {
      "text": _vm.projectData.title ? _vm.projectData.title : 'Новый проект'
    }
  }), _c('el-button', {
    staticClass: "project__title-button",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.addOffer
    }
  }, [_c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("+ ")]), _vm._v("Добавить "), _c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("вакансию")])])], 1), _c('div', {
    staticClass: "project__tabs",
    class: ['project__tabs--' + _vm.activeTab]
  }, [_c('el-tabs', {
    on: {
      "tab-click": _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Пользователи",
      "name": "vacancy"
    }
  }, [_c('OffersTable')], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Информация",
      "name": "info"
    }
  }, [_vm.isLoad ? _c('ProjectCard', {
    attrs: {
      "data-info": _vm.projectData
    }
  }) : _vm._e()], 1)], 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }